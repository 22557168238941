<script setup lang="ts">
import type { VSelectProps } from '~/components/organisms/VDialogSelect/VDialogSelect.vue'
import type { Agency } from '~/types/api'
import { getDateAvailableTimes } from '~/utils/reservation/get-date-available-times'

interface Props extends VSelectProps {
    mode?: 'start' | 'end'
    agency?: Agency
    modelValue: Date[]
}

const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue'])

const date = computed({
    get() {
        return props.modelValue[props.mode === 'start' ? 0 : 1]
    },
    set(value) {
        const newValue = [...props.modelValue]

        newValue[props.mode === 'start' ? 0 : 1] = value

        emit('update:modelValue', newValue)
    },
})

watch(date, () => {
    // autoset the first time on date selection
    if (timeIndex.value === -1 && date.value && times.value[0]) {
        date.value = times.value[0]
    }
})

const internalModel = computed({
    get() {
        return date.value?.toString()
    },
    set(value: string) {
        date.value = new Date(value)
    },
})

// MIN DEPARTURE DATE
const minDepartureHoursDelay = computed(() => props.agency?.minimumDepartureHoursDelay || 0)

const minDepartureDate = computed<Date>(() => {
    const date = new Date()

    // add the minimum departure hours delay
    date.setHours(date.getHours() + minDepartureHoursDelay.value)

    return date
})

const times = computed<Date[]>(() => {
    if (!date.value) return []

    const result = getDateAvailableTimes(date.value, openingHoursSpecification.value, minDepartureDate.value)

    return result.sort((a, b) => {
        return a > b ? 1 : -1
    })
})

const formattedTimes = computed(() => {
    return times.value.map((time) => `${time.getHours()}h${('0' + time.getMinutes()).slice(-2)}`)
})

const timeIndex = computed(() => {
    return times.value.findIndex((time) => time.getTime() === date.value?.getTime())
})

const openingHoursSpecification = computed(() => {
    return props.agency?.openingHoursSpecification || []
})

const options = computed(() => {
    return times.value.map((time, index) => ({
        value: time.toString(),
        label: formattedTimes.value[index],
    }))
})

const label = computed(() => {
    return props.mode === 'start'
        ? 'rent_a_car.departure.time_picker.dialog.title'
        : 'rent_a_car.return.time_picker.dialog.title'
})
</script>

<template>
    <VDialogSelect
        :key="internalModel"
        v-model="internalModel"
        :class="$style.root"
        :placeholder="$t('rent_a_car.time_picker.placeholder')"
        :dialog-title="$t(label)"
        :options="options"
        :disabled="disabled"
        icon="clock"
    />
</template>

<style lang="scss" module>
.root {
    --input-background-image: none;
    --input-border-radius: 0 var(--radius-sm) var(--radius-sm) 0;
    --input-padding: 0 var(--spacing-2-xs) 0 calc(var(--spacing-2-xs) + #{rem(24 + 4)});
    --v-input-root-width: auto;

    min-width: fit-content;
    margin-left: -1px;
}
</style>
