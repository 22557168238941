<script setup lang="ts">
import type { ReservationAgency } from '~/components/organisms/VReservationForm/VReservationForm.vue'

interface Props {
    id?: string
    label?: string
    mode: 'start' | 'end'
    agency?: ReservationAgency
    modelValue: Date[]
    disabled?: boolean
    error?: string
    required?: boolean
}

const props = defineProps<Props>()

const emit = defineEmits(['update:modelValue'])

const internalModelValue = computed({
    get: () => props.modelValue || [],
    set: (value: Date[] | null) => emit('update:modelValue', value || []),
})

const { t } = useI18n()

const datePickerPlaceholder = computed(() => {
    return t(
        props.mode === 'start'
            ? 'rent_a_car.departure.date_picker.placeholder'
            : 'rent_a_car.return.date_picker.placeholder',
    )
})

const selectedDate = computed(() => {
    return internalModelValue.value[props.mode === 'start' ? 0 : 1]
})
</script>

<template>
    <VInput
        :id="id || (mode === 'start' ? 'datetime-departure' : 'datetime-return')"
        :disabled="disabled"
        :required="required"
        :class="$style.root"
        :label="label || $t(mode === 'start' ? 'rent_a_car.departure.label' : 'rent_a_car.return.label')"
        :error="error"
    >
        <div :class="$style.inner">
            <ClientOnly>
                <template #placeholder><VRangePickerPlaceholder :label="datePickerPlaceholder" /></template>
                <Suspense timeout="0">
                    <template #fallback><VRangePickerPlaceholder :label="datePickerPlaceholder" /></template>
                    <VReservationDatePicker
                        v-model="internalModelValue"
                        :agency="agency"
                        :disabled="disabled"
                        :mode="mode"
                    />
                </Suspense>
            </ClientOnly>
            <VReservationTimePicker
                v-model="internalModelValue"
                :agency="agency"
                :disabled="disabled || !selectedDate"
                :mode="mode"
            />
        </div>
    </VInput>
</template>

<style lang="scss" module>
.root {
    --v-input-root-width: 100%;

    order: 1;

    @include media('>=md') {
        --v-input-root-width: auto;

        flex-grow: 1;
    }

    @include media('>=vl') {
        --v-input-root-width: 100%;
    }
}

.inner {
    display: flex;
    width: 100%;
}
</style>
