import type { DayOfWeek, OpeningHours } from '~/types/api'

export const weekDays = {
    'https://schema.org/Sunday': 0,
    'http://schema.org/Sunday': 0,
    Sunday: 0,
    'https://schema.org/Monday': 1,
    'http://schema.org/Monday': 1,
    Monday: 1,
    'https://schema.org/Tuesday': 2,
    'http://schema.org/Tuesday': 2,
    Tuesday: 2,
    'https://schema.org/Wednesday': 3,
    'http://schema.org/Wednesday': 3,
    Wednesday: 3,
    'https://schema.org/Thursday': 4,
    'http://schema.org/Thursday': 4,
    Thursday: 4,
    'https://schema.org/Friday': 5,
    'http://schema.org/Friday': 5,
    Friday: 5,
    'https://schema.org/Saturday': 6,
    'http://schema.org/Saturday': 6,
    Saturday: 6,
} as Record<DayOfWeek, number>

export const formatLocalDateOnly = (date: Date | string | null | undefined): string => {
    if (!date) {
        return ''
    }
    if (typeof date === 'string') {
        date = new Date(date)
    }
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
}

export const getOpeningHoursForDate = (
    date: Date | string | undefined | null,
    openingHoursSpecification: Array<OpeningHours>,
): Array<OpeningHours> => {
    type DayOfWeekTuple = [DayOfWeek, number]
    if (!date) {
        return []
    }
    if (typeof date === 'string') {
        date = new Date(date)
    }
    const datePart = formatLocalDateOnly(date)

    /*
     * First check if there is a validFrom and validThrough for the current date
     */
    const openingHoursSpecificationWithValidFromAndValidThrough = openingHoursSpecification.filter(
        (openingHours: OpeningHours) =>
            openingHours.validFrom !== undefined &&
            openingHours.validThrough !== undefined &&
            openingHours.validFrom <= datePart &&
            openingHours.validThrough >= datePart,
    )
    if (openingHoursSpecificationWithValidFromAndValidThrough.length > 0) {
        return openingHoursSpecificationWithValidFromAndValidThrough
    }

    const dayOfWeek = date.getDay() as number
    // Get all allowed variants for the current dayOfWeek
    const dayOfWeekStrings = (Object.entries(weekDays) as Array<DayOfWeekTuple>)
        .filter((day) => day[1] === dayOfWeek)
        .map((day) => day[0]) as Array<DayOfWeek>
    if (dayOfWeekStrings.length === 0) {
        return []
    }
    return openingHoursSpecification.filter((openingHours: OpeningHours) => {
        if (!openingHours.dayOfWeek) {
            return false
        }
        // Remove closing days
        if (openingHours.opens === '00:00' && openingHours.closes === '00:00') {
            return false
        }
        // Remove closing days with seconds
        if (openingHours.opens === '00:00:00' && openingHours.closes === '00:00:00') {
            return false
        }
        // If OpeningHours dayOfWeek is a string, check if it includes the current dayOfWeek variants
        if (typeof openingHours.dayOfWeek === 'string' && dayOfWeekStrings.includes(openingHours.dayOfWeek)) {
            return true
        }
        // If OpeningHours dayOfWeek is an array, check if it includes the current dayOfWeek variants
        return (
            Array.isArray(openingHours.dayOfWeek) &&
            dayOfWeekStrings.filter((value) => openingHours.dayOfWeek?.includes(value)).length > 0
        )
    })
}
